.ant-layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  min-height: 0;
  background: #fff;
}
.ant-layout-header {
  height: 64px;
  padding: 0 50px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 64px;
  background: #fff;
}
.ant-layout-footer {
  padding: 24px 50px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  background: #fff;
}
.ant-form {
  margin: 0 auto;
}
.searchfrom {
  width: 70vw;
  margin: 0 auto;
}
.ant-btn {
  margin-right: 8px;
}
