.site-drawer-render-in-current-wrapper {
  position: relative;
  min-height: calc(100vh - 140px);
  overflow: hidden;
  text-align: center;
  background: #fafafa;
  border-radius: 2px;
}
.site-drawer-render-in-current-wrapper-client {
  position: relative;
  min-height: calc(100vh - 140px);
  overflow: hidden;
  text-align: center;
  background: #fafafa;
  border-radius: 2px;
}
.site-drawer-render-in-current-wrapper-client-no-min-h {
  position: relative;
  height: 100%;
  overflow: hidden;
  text-align: center;
  background: #fafafa;
  border-radius: 2px;
}
.site-drawer-render-in-current-wrapper-client-no-min-h .ant-table-body {
  /*overflow: auto !important;*/
}
.box-row {
  display: flex;
  flex-direction: row;
}
.box-column {
  display: flex;
  flex-direction: column;
}
