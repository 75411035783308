.home-wrap {
  height: calc(72vh);
  width: 95%;
  position: absolute;
  margin-top: 25px;
  background-color: white;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}
