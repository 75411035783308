.fonter {
  padding: 40px 0;
  text-align: center;
  -webkit-columns: #d7d7d7;
  columns: #d7d7d7;
  height: 50px;
  position: absolute;
  bottom: 10px;
  left: 40%;
}
