.header {
  width: 100%;
  background-color: white;
}
.header .header-top {
  height: 60px;
  line-height: 60px;
  padding: 0 20px;
  text-align: right;
}
.header .header-top a {
  margin-left: 40px;
}
.header .breadcrumb {
  height: 40px;
  line-height: 40px;
  padding: 0 20px 0 0;
  border-top: 1.5px solid #f9c700;
}
.header .breadcrumb .breadcrumb-title {
  font-size: 16px;
}
.header .breadcrumb .breadcrumb-title.after {
  position: absolute;
  content: '';
  left: 10px;
  top: 39px;
  border-top: 9px solid white;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
}
.header .breadcrumb .breadcrumb-detail {
  text-align: right;
  font-size: 16px;
}
.header .breadcrumb .breadcrumb-detail .date {
  margin-left: 10px;
  vertical-align: center;
}
.header .breadcrumb .breadcrumb-detail .weather-img {
  margin-left: 5px;
}
.header .breadcrumb .breadcrumb-detail .weather-img img {
  height: 15px;
}
.header .breadcrumb .breadcrumb-detail .weather-detail {
  margin-left: 5px;
  vertical-align: center;
}
