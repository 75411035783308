.logo {
  line-height: 100px;
  padding-left: 20px;
  background-color: #002140;
}
.logo img {
  height: 45px;
}
.logo h1 {
  color: #ffffff;
  font-size: 25px;
  display: inline-block;
  vertical-align: center;
  margin: 0 0 0 10px;
}
.nav-left-menu {
  height: calc(100vh - 105px - 40px);
  overflow-y: auto;
}
