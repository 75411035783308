body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a {
  color: #606266;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
/*scrollbar*/
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  position: absolute;
}
::-webkit-scrollbar-thumb {
  background-color: #5bc0de;
}
::-webkit-scrollbar-track {
  background-color: #ddd;
}
/*scrollbar*/
/*.ant-form-item{*/
/*  margin-bottom:0px !important;*/
/*}*/
.ant-table-body {
  min-height: calc(20vh) !important;
  /*max-height: calc(75vh - 140px) !important;*/
  /*overflow: auto !important;*/
}
.detail .ant-table-body {
  /*min-height: calc((((25vh - 140px) - 40px) - 30px) - 60px) !important;*/
  /*max-height: calc((((70vh - 140px) - 40px) - 30px) - 60px) !important;*/
  /*overflow: auto !important;*/
}
