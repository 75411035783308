.bg {
  height: 100vh;
  background: linear-gradient(180deg, #a0d7e7, #6c5dd3);
  margin: auto;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
}
.login_card {
  width: 520px;
  height: 450px;
  background: #f2f3f7;
  border-radius: 20px;
  margin: auto;
  text-align: center;
  justify-content: center;
  padding: 51px 60px;
}
.login-button {
  width: 400px;
  height: 56px;
  background: #6c5dd3;
  border-radius: 12px;
}
.heard {
  position: absolute;
  display: flex;
  top: 64px;
}
.title {
  width: 385px;
  font-size: 30px;
  font-family: Arial;
  font-weight: bold;
  color: #151830;
}
.cloud {
  width: 100px;
  height: 72px;
  line-height: 72px;
}
.cloud img {
  width: 40px;
  height: 40px;
}
.footer {
  width: 100%;
  height: 12px;
  font-size: 10px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: #151830;
  background: none;
  bottom: 34px;
  left: 0;
  position: absolute;
}
